.error-input{
    border: 1px solid red !important;
}

.ical-providers {
    border: 1px solid #ccc;
    border-radius: 6px;
    height: 50px;
    width: 100%;
    position: relative;
    filter: grayscale(1);
    padding: 12px 10px;
    transition: all ease 0.5s;

    &:hover {
        filter: grayscale(0) !important;
        transition: all ease 0.5s;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.service-card {
    border: 1px solid #e1e1e1 !important;
    min-height: 170px !important;
    height: 100% !important;
    text-align: center;
    padding: 40px 10px;
    cursor: pointer;
}

.service-card.selected {
    border: 1px solid #444 !important;
}

.service-checklist-btn {
    z-index: 99999;
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 50px;
    text-align: center;
}

.review-line {
    color: #FE5D5D;
    text-align: center;
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
}

.review-detail-row {
    list-style: none;

    .score {
        display: flex;
        height: 30px;
        align-items: center;
        margin: 6px 0;
    }
}

.svg-icon.trustbadge svg [fill]:not(.permanent):not(g) {
    fill: #fff !important
}

.offer-action-buttons {
    position: absolute;
    left: 0;
    bottom: 10px;
    padding: 0 12px;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .offer-action-buttons {
        position: relative !important;
        left: unset !important;
        bottom: unset !important;
        padding: 0 !important;
        width: 100%;
    }
}