//
// Layout Config
//

// Root font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Content background Color
$content-bg-color: if(isDarkSkin(), #151521, $gray-100) !default;
$content-border-radius: 1.5rem !default;

// Content Spacing
$content-spacing: (
  desktop: 30px,
  // Padding for desktop mode
  tablet-and-mobile: 15px // Padding for tablet and mobile modes,,,,,,,,,,,,,,,,
) !default;

// Content Spacing(with non fixed toolbar)
$content-spacing-toolbar: (
  desktop: 15px,
  // Padding for desktop mode
  tablet-and-mobile: 10px // Padding for tablet and mobile modes,,,,,,,,,,,,,,,,
) !default;

// Header
$header-config: (
  // Default Mode
  default:
    (
      height: (
        desktop: 65px,
        tablet-and-mobile: 55px,
      ),
      bg-color: if(isDarkSkin(), #1e1e2d, $white),
    ),
  // Fixed Mode
  fixed:
    (
      height: (
        desktop: 65px,
        tablet-and-mobile: 55px,
      ),
      z-index: 100,
      bg-color: if(isDarkSkin(), #1e1e2d, $white),
      box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05),
    )
) !default;

// Toolbar
$toolbar-config: (
  // Fixed Mode
  fixed:
    (
      height: (
        desktop: 75px,
        tablet-and-mobile: 60px,
      ),
      z-index: 99,
      bg-color: if(isDarkSkin(), #1b1b28, $white),
      box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05),
      border-color: $border-color,
    )
) !default;

// Aside
$aside-config: (
  z-index: 101,
  // Aside's z-index property
  transition-speed: 0.3s,
  // transition speed
  padding-x: 25px,
  menu-indention: 0.75rem,
  bg-color: #1e1e2d,
  logo-bg-color: #1b1b28,
  scrollbar-color: if(isDarkSkin(), $gray-200, #3b3b64),
  scrollbar-hover-color: if(isDarkSkin(), $gray-300, lighten(#3b3b64, 3%)),
  width: 265px,
  // Aside width for desktop mode
  minimized-width: 75px,
  // Aside minimized width for desktop mode
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05),
  // Aside box shadow
  minimized-hover-box-shadow: 5px 0px 10px rgba(70, 78, 95, 0.075)
    // Monimized hover box shadow,,,,,,,,,,,,,,,,
) !default;

// Aside
$footer-config: (
  bg-color: if(isDarkSkin(), #1b1b28, $white),
) !default;
